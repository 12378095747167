<template>
  <div class="views-holder">
    <NewViewOrderDetails />
  </div>
</template>

<script>
import NewViewOrderDetails from "@/components/pages/profile/orders/NewViewOrderDetails.vue";
export default {
  components: {
    NewViewOrderDetails,
  },
  metaInfo: {
    title: "View Order Details page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  mounted() {
    if (this.$route.query.id) {
      sessionStorage.setItem("ODID", this.$route.query.id);
    }
    setTimeout(() => {
      if (sessionStorage.getItem("ODID")) {
        this.$router.push({
          name: this.$route.name, // current route name
          query: {
            ...this.$route.query, // keep the existing query parameters
            id: sessionStorage.getItem("ODID"), // add or update the 'id' query parameter
          },
        });
      }
    }, 3000);
  },
};
</script>

<style>
</style>